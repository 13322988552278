<template>
  <b-switch
    :value="task.needsAttention"
    class="has-margin-top-25"
    :class="{ 'has-cursor-wait': processing }"
    :disabled="processing"
    type="is-success"
    @input="markAsNeedsAttenton"
  >
    {{ task.needsAttention ? "Required" : "Not required" }}
  </b-switch>
</template>

<script>
import { deleteField, doc, updateDoc } from "@firebase/firestore";
export default {
  name: "TaskNeedsAttentionContorl",
  props: {
    task: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      processing: false
    };
  },
  methods: {
    markAsNeedsAttenton(needsAttention) {
      this.processing = true;
      const taskRef = doc(this.$firestore, `tasks/${this.task._id}`);
      updateDoc(taskRef, {
        needsAttention,
        dateSinceAgentInteraction: deleteField()
      })
        .then(() => {
          this.processing = false;
        })
        .catch(error => {
          this.processing = false;
          this.$toast.open({
            message: error.message,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
